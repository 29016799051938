<template>
    <div class="outer-contant">
        <div class="outer-box">
            <div class="ins-one" v-for="(item, index) in qualification_pictures" :key="index">
                <div class="ins-title">
                    <span style="color: red;margin-right: 3px" v-if="item.verify === 'required'">*</span>
                    <span>{{ item.name }}</span>
                </div>
                <div class="ins-contant">
                    <el-upload
                        v-if="item.key !== 'unified_code'"
                        :class="[{ 'disabled': item.image }]"
                        :on-change="handleChange.bind(null, index)"
                        :file-list="item.fileList"
                        action="https://juxie.shenglitaiyy.com//api/upload/headimg"
                        :on-success="dynamicPicSuccess.bind(null, { 'index': index, 'data': item })"
                        list-type="picture-card"
                        :before-remove="dynamicPicRemove.bind(null, { 'index': index, 'data': item })"
                        :limit="1"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <template v-else>
                        <div style="margin-top: 30px;">
                            <el-input :placeholder="`请输入${item.name}`" v-model="item.value"></el-input>
                        </div>
                    </template>
                </div>
                <div class="ins-footer">
                    <template v-if="item.key !== 'unified_code'">
                        <div class="ins-time">时效：</div>
                        <div class="ins-radio">
                            <el-radio-group v-model="item.expiry_date_type">
                                <el-radio value="永久" label="永久"></el-radio>
                                <el-radio value="时效" label="时效"></el-radio>
                            </el-radio-group>
                        </div>
                    </template>
                    <div
                        class="ins-date"
                        v-show="item.expiry_date_type === '时效'"
                        :class="{ choice_date: expiry_date_flag(item) }"
                    >
                        <el-date-picker
                            style="width: 100%"
                            unlink-panels
                            v-model="item.limitation_date"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            value-format="yyyy-MM-dd"
                            end-placeholder="结束日期"
                        ></el-date-picker>

                        <template v-if="expiry_date_flag(item)">
                            <div class="notice">请选择有效期限</div>
                        </template>
                    </div>

                    <template v-if="item.image && item.status_label">
                        <div class="audit-status">
                            <span>审核状态：</span>
                            <span
                                :class="[item.status == 1 ? 'audit-status-success' : 'audit-status-fail']"
                            >{{ item.status_label }}</span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="upload-btn">
                <el-button type="primary" style="width: 200px" @click="submitQualification">提交资质</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { saveUpload } from "@/api/member/member"
import http from "@/utils/http"

export default {
    data() {
        return {
            dialogImageUrl: "",
            dialogVisible: false,
            radio: "永久",
            value1: "",
            qualification_pictures: [], //商家资质图片,
            member_id: this.$store.state.member.member.member_id
        }
    },
    mounted() {
        this.requestQualificationImages()
    },
    watch: {
        "$store.state.member.member.member_id": {
            handler(val) {
                this.member_id = val
                if (val) this.requestQualificationImages()
            }
        }
    },
    computed: {
        expiry_date_flag() {
            return item => {
                return item.expiry_date_type === "时效" && item.limitation_date.length !== 2
            }
        }
    },
    methods: {
        // 1.
        dynamicPicRemove(obj, res, file) {
            this.changeUploadDomShowState(obj.index, "block")
            this.qualification_pictures[obj.index].image = ""
            this.qualification_pictures[obj.index].fileList = []
        },
        dynamicPicSuccess(obj, res, file) {
            if (obj.data.status == "1" || obj.data.status == "" || obj.data.status == "2") {
                this.qualification_pictures[obj.index].status = "0"
                this.qualification_pictures[obj.index].status_label = "待审核"
            }
            this.qualification_pictures[obj.index].image = res.data.pic_path
        },
        handleChange(Click_index) {
            this.changeUploadDomShowState(Click_index)
        },
        // 开局请求图片
        requestQualificationImages() {
            const member_id = this.member_id
            saveUpload({ member_id })
                .then(result => {
                    if (result.code >= 0) {
                        this.qualification_pictures = result.data
                        this.qualification_pictures.forEach(ele => {
                            this.$set(ele, "limitation_date", [])
                            // 如果是时效回传了时间拆分为数组 否则赋值永久
                            if (ele.expiry_date.includes("至")) {
                                ele.limitation_date = ele.expiry_date.split("至")
                            }
                            // 判断回传的资质类型
                            if (ele.expiry_date === "永久" || ele.expiry_date === "") {
                                this.$set(ele, "expiry_date_type", "永久")
                            } else {
                                this.$set(ele, "expiry_date_type", "时效")
                            }
                            if (ele.image) {
                                this.$set(ele, "fileList", [
                                    {
                                        name: ele.image,
                                        url: ele.image
                                    }
                                ])
                            } else {
                                this.$set(ele, "fileList", [])
                            }
                        })
                        this.$forceUpdate()
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        },
        // 上传资质
        async submitQualification() {
            let flag = false
            try {
                // 验证所有必传资质是否上传了图片和统一信用代码
                this.qualification_pictures.forEach(ele => {
                    if (ele.image === "" && ele.verify === "required") {
                        throw new Error(`请上传${ele.name}图片`)
                    } else if (ele.value == "" && ele.verify === "required" && ele.key === "unified_code") {
                        throw new Error(`请填写${ele.name}`)
                    }
                })
                // 验证三选一资质是否上传了图片
                const group_qualification_pictures = this.qualification_pictures.filter(ele => ele.verify === "either")
                let one_out_of_three = group_qualification_pictures.some(ele => ele.image !== "")
                if (group_qualification_pictures.length > 0 && !one_out_of_three) {
                    const group_qualification_pictures_name = group_qualification_pictures.map(ele => ele.name).join("、")
                    throw new Error(`${group_qualification_pictures_name}，请任选其一上传图片`)
                }
            } catch (e) {
                flag = true
                this.$alert(e.message, "提示", {})
            }
            if (flag) return false
            // 深拷贝对需要提交的数据进行处理
            // expiry_date_type 根据资质时效类型提交 永久
            let submitData = this.$util.deepClone(this.qualification_pictures)
            try {
                submitData.forEach(ele => {
                    if (ele.expiry_date_type === "时效" && ele.limitation_date.length !== 2) {
                        throw new Error("请选择时效有效期")
                    }
                    if (ele.expiry_date_type === "永久") {
                        ele.expiry_date = "永久"
                    } else if (ele.expiry_date_type === "时效" && ele.limitation_date.length === 2) {
                        ele.expiry_date = ele.limitation_date.join("至")
                    }
                })
            } catch (e) {
                return this.$message({
                    message: e.message,
                    type: "error"
                })
            }

            const result = await http({
                url: "/api/register/aptitudeupdate",
                data: { aptitude_json: JSON.stringify(submitData) }
            })
            let type
            let message
            if (result.code >= 0) {
                type = "success"
                message = "提交成功"
                this.requestQualificationImages()
                setTimeout(() => {
                    this.$router.push({
                        name: "index"
                    })
                }, 1500)
            } else {
                type = "error"
                message = res.message
            }
            this.$message({
                message,
                type
            })
        },
        changeUploadDomShowState(clickIndex, state = "none") {
            let allDom = document.querySelectorAll(".el-upload")
            let dom = allDom[clickIndex]
            if (state === "none") {
                dom.style.display = "none"
            } else {
                dom.style.display = "block"
            }
        }
    }
}
</script>

<style lang="scss">
.disabled .el-upload--picture-card {
    display: none;
}

.el-list-enter-active,
.el-list-leave-active {
    transition: none;
}

.el-list-enter,
.el-list-leave-active {
    opacity: 0;
}

.choice_date {
    .el-input__inner {
        //background-color: pink;
        border: 1px solid red !important;
    }

    .notice {
        font-size: 12px;
        color: #ff4544;
    }
}

.audit-status {
    padding: 0 20rpx;

    .audit-status-fail {
        color: red;
    }

    .audit-status-success {
        color: #09b307;
    }
}

.outer-contant {
    width: 70vw;
    background-color: #fff;
    box-sizing: border-box;
    padding: 15px;

    .outer-box {
        // box-sizing: border-box;
        // padding: 15px;
        display: flex;
        flex-wrap: wrap;

        .ins-one {
            width: 250px;
            height: 400px;
            box-sizing: border-box;
            padding: 5px;
            border: 1px solid #858585;
            margin: 10px 5px 0 0;

            .ins-title {
                width: 100%;
                height: 15%;
                text-align: center;
                box-sizing: border-box;
                padding-top: 10%;
            }

            .ins-contant {
                width: 100%;
                height: 50%;
                display: flex;
                justify-content: center;
            }

            .ins-footer {
                width: 100%;

                .ins-radio {
                    width: 100%;
                    margin: 0 auto;
                }

                .el-date-picker {
                    width: 100px;
                }
            }
        }
    }

    .upload-btn {
        width: 100%;
        height: 48px;
        margin-top: 25px;
        // background-color: blueviolet;
        display: flex;
        justify-content: center;
    }
}
</style>